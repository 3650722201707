import './PuxColumns.scss'

import React, { FunctionComponent } from 'react'

const rootClass = `pux-columns`

const PuxColumns: FunctionComponent = (props) => {
  return <div className={rootClass}>{props.children}</div>
}

export const PuxMainColumn: FunctionComponent = (props) => {
  return <div className={`${rootClass}-main`}>{props.children}</div>
}

export const PuxSideColumn: FunctionComponent = (props) => {
  return <div className={`${rootClass}-side`}>{props.children}</div>
}

export default PuxColumns
