import './ChangeLogPage.scss'

import loadable from '@loadable/component'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { ChangeLogDataType } from 'Shared/utils/getChangeLogData'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import Layout from '../../components/core/Layout'

const ChangeLogTable = loadable(() => import(`./ChangeLogTable`))

export interface ChangeLogType {
  contentType: string
  path: string
  contentItemId: string
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

export interface ChangeLogPageType extends PageProps {
  data: {
    orchard: {
      easySoftwareChangeLog: [ChangeLogType]
    }
  }
  pageContext: {
    pagePath: string
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    changeLog: ChangeLogDataType
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareChangeLog(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareChangeLog {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

const rootClass = `change-log`

const ChangeLogPage: FunctionComponent<ChangeLogPageType> = (props) => {
  const data = props.data.orchard.easySoftwareChangeLog[0]
  const breadcrumbsData = props.pageContext.breadcrumbs

  const buildChangeLogTable = (version: string, key: number) => {
    const dataByVersion =
      props.pageContext.changeLog.data?.filter(
        (item) => item.version === version
      ) ?? null

    if (dataByVersion !== null) {
      return <ChangeLogTable data={dataByVersion} version={version} key={key} />
    }

    return null
  }

  return (
    <Layout localizedPath={data.localization?.localizations}>
      <EasySoftwareBreadcrumbs items={breadcrumbsData} />
      <PuxMetaTags {...props.pageContext.metaTags} />
      <div className='pux-container'>
        <div className={rootClass}>
          <PuxColumns>
            <PuxMainColumn>
              <h1 className={`${rootClass}-title`}>{data.displayText}</h1>

              {props.pageContext.changeLog.versions?.map((version, idx) =>
                buildChangeLogTable(version, idx)
              )}
            </PuxMainColumn>
            <PuxSideColumn>
              <div className='sidebar-sticky'>
                <WidgetBuilder
                  widgetBuilderData={props.pageContext.sideCardWidgets}
                />
              </div>
            </PuxSideColumn>
          </PuxColumns>
        </div>
      </div>
    </Layout>
  )
}

export default ChangeLogPage
